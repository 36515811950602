import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ResponsiveType, useResponsive, BodyLarge, FontWeight, HSpaced } from '@thesisedu/web/dist'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useVideoSeriesQuery } from './VideoSeriesWidget'
import { VideoSeriesCompletionCheckbox } from './VideoSeriesCompletion'

export interface VideoSeriesNavigationProps {
  selectedVideoId?: string
}
export function VideoSeriesNavigation({ selectedVideoId }: VideoSeriesNavigationProps) {
  const items = useVideoSeriesQuery()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const isMedium = useResponsive(ResponsiveType.GreaterThan, 'md')
  React.useEffect(() => {
    if (isMedium && selectedVideoId && containerRef.current) {
      setTimeout(() => {
        containerRef.current!.querySelector(`#video-${selectedVideoId}`)?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest'
        })
      }, 100)
    }
  }, [isMedium, selectedVideoId])
  return (
    <Container ref={containerRef}>
      {items.map((video) => (
        <NavigationItem
          key={video.id}
          id={`video-${video.childMarkdownRemark.frontmatter.videoId}`}
          to={`/learn/tutorial-series${video.childMarkdownRemark.fields.slug}`}
          className={
            selectedVideoId === video.childMarkdownRemark.frontmatter.videoId ? 'selected' : ''
          }
        >
          <HSpaced>
            <VideoSeriesCompletionCheckbox
              videoId={video.childMarkdownRemark.frontmatter.videoId}
            />
            <BodyLarge weight={FontWeight.SemiBold}>
              {video.childMarkdownRemark.frontmatter.title}
            </BodyLarge>
            <BodyLarge className={'arrow'}>
              <ArrowRightOutlined />
            </BodyLarge>
          </HSpaced>
        </NavigationItem>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: block;
`
const NavigationItem = styled(Link)`
  padding: ${(props) => props.theme['@size-xs']} ${(props) => props.theme['@size-s']};
  border-radius: ${(props) => props.theme['@border-radius-base']} 100px 100px
    ${(props) => props.theme['@border-radius-base']};
  transition: color 0.25s linear, background 0.25s linear;
  display: block;
  color: ${(props) => props.theme['@gray-5']};
  margin-bottom: ${(props) => props.theme['@size-xs']};
  .arrow {
    transition: opacity 0.25s linear, transform 0.25s cubic-bezier(0.7, 0, 0.84, 0);
    transform: translateX(-15px);
    opacity: 0;
    font-size: ${(props) => props.theme['@size-m']};
    color: ${(props) => props.theme['@gray-7']};
    margin: 0 0 0 auto;
  }
  &:hover,
  &.selected {
    background: ${(props) => props.theme['@background-color-base']};
    color: ${(props) => props.theme['@gray-7']};
    .ant-checkbox-inner {
      background: ${(props) => props.theme['@gray-2']};
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: ${(props) => props.theme['@primary-color']};
    }
    .arrow {
      transition: opacity 0.5s linear, transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      transform: translateX(0);
      opacity: 1;
    }
  }
`
