import * as React from 'react'
import { graphql } from 'gatsby'

import { HSpaced, H3Alternate, Space, media, Block, H2, HideOn } from '@thesisedu/web/dist'
import styled from 'styled-components'
import { VideoSeriesTemplateQueryQuery } from '../graphqlTypes'
import { Container, LargeH1, Subtitle } from '../components/styled'
import { renderAst } from '../components/rehype'
import { SEO } from '../components/SEO'
import { Video } from '../components/Video'
import { VideoSeriesNavigation } from '../components/video-series/VideoSeriesNavigation'
import { VideoSeriesCompletionLink } from '../components/video-series/VideoSeriesCompletion'
import { VideoCompletionContextProvider } from '../components/video-series/VideoCompletionContext'

export interface VideoSeriesTemplateProps {
  data: VideoSeriesTemplateQueryQuery
}
const VideoSeriesTemplate: React.FC<VideoSeriesTemplateProps> = ({ data }) => (
  <VideoCompletionContextProvider>
    <Container>
      <SEO title={`${data.markdownRemark.frontmatter.title} - Tutorial Series`} article />
      <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
        <LargeH1 isBlock={false}>Teacher Tutorial Series</LargeH1>
        <Block marginTop={'@size-xs'}>
          <Subtitle>Get started with Prodigies Academy quickly.</Subtitle>
        </Block>
      </Block>
      <ContentContainer>
        <div className={'navigation'}>
          <HideOn size={'md'}>
            <H2 isBlock>Other Tutorial Videos</H2>
          </HideOn>
          <VideoSeriesNavigation selectedVideoId={data.markdownRemark.frontmatter.videoId} />
        </div>
        <div className={'content'}>
          <Video id={data.markdownRemark.frontmatter.videoId} aspect={9.28 / 16} />
          <Block marginTop={'@size-l'} marginBottom={'@size-xs'}>
            <HSpaced>
              <H3Alternate isBlock={false}>{data.markdownRemark.frontmatter.title}</H3Alternate>
              <Space />
              <VideoSeriesCompletionLink videoId={data.markdownRemark.frontmatter.videoId} />
            </HSpaced>
          </Block>
          {renderAst(data.markdownRemark.htmlAst)}
        </div>
      </ContentContainer>
    </Container>
  </VideoCompletionContextProvider>
)

const ContentContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  > .navigation {
    order: 2;
    transition: width 0.25s ease-in-out;
  }
  > .content {
    order: 1;
    margin-bottom: ${(props) => props.theme['@size-l']};
  }
  ${media.md} {
    flex-direction: row;
    > .navigation {
      order: 1;
      width: 250px;
      max-height: 700px;
      overflow-y: auto;
    }
    > .content {
      order: 2;
      flex: 1;
      margin-left: ${(props) => props.theme['@size-l']};
      min-width: 0;
    }
  }
  ${media.lg} {
    > .navigation {
      width: 350px;
    }
  }
`

export default VideoSeriesTemplate

export const query = graphql`
  query VideoSeriesTemplateQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        videoId
      }
    }
  }
`
