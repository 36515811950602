import React from 'react'
import { Button, Checkbox } from 'antd'
import { useVideoCompletionContext } from './VideoCompletionContext'

export function useVideoCompletion(videoId: string) {
  const { completedVideos, setCompleted } = useVideoCompletionContext(true)
  return {
    isComplete: completedVideos.includes(videoId),
    setIsComplete: (complete: boolean) => setCompleted(videoId, complete)
  }
}

export interface VideoSeriesCompletionProps {
  videoId: string
}
export function VideoSeriesCompletionCheckbox({ videoId }: VideoSeriesCompletionProps) {
  const { isComplete, setIsComplete } = useVideoCompletion(videoId)
  return (
    <Checkbox
      checked={isComplete}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => setIsComplete(e.target.checked)}
    />
  )
}

export function VideoSeriesCompletionLink({ videoId }: VideoSeriesCompletionProps) {
  const { isComplete, setIsComplete } = useVideoCompletion(videoId)
  return (
    <Button type={'link'} size={'small'} onClick={() => setIsComplete(!isComplete)}>
      {isComplete ? 'Mark as Incomplete' : 'Mark as Complete'}
    </Button>
  )
}
