import React from 'react'

export interface VideoCompletionContextValue {
  completedVideos: string[]
  setCompleted: (videoId: string, complete: boolean) => void
}
export const VideoCompletionContext = React.createContext<VideoCompletionContextValue | undefined>(
  undefined
)

const SEP = '|'
const LOCALSTORAGE_KEY = 'video-completion'
export function VideoCompletionContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [completedVideos, setCompletedVideos] = React.useState<string[]>([])
  React.useEffect(() => {
    setCompletedVideos((localStorage.getItem(LOCALSTORAGE_KEY) || '').split(SEP))
  }, [])
  const value = React.useMemo<VideoCompletionContextValue>(
    () => ({
      completedVideos,
      setCompleted(videoId, complete) {
        const without = completedVideos.filter((v) => v !== videoId)
        const newCompleted = complete ? [...without, videoId] : without
        localStorage.setItem(LOCALSTORAGE_KEY, newCompleted.join(SEP))
        setCompletedVideos(newCompleted)
      }
    }),
    [completedVideos]
  )
  return <VideoCompletionContext.Provider value={value}>{children}</VideoCompletionContext.Provider>
}

export function useVideoCompletionContext(): VideoCompletionContextValue | undefined
export function useVideoCompletionContext(require: false): VideoCompletionContextValue | undefined
export function useVideoCompletionContext(require: true): VideoCompletionContextValue
export function useVideoCompletionContext(
  require?: boolean
): VideoCompletionContextValue | undefined {
  const context = React.useContext(VideoCompletionContext)
  if (!context && require) {
    throw new Error('VideoCompletionContext is required, yet not provided.')
  }
  return context
}
